import React, {useCallback, useEffect, useReducer, useState} from 'react'
import {Action, IWidgets, reducer, State} from './reducer'
import Modal from 'react-bootstrap/Modal'
import {Button as ButtonMUI} from '@mui/material'
import Step1 from './Step1'
import {useIntl} from 'react-intl'
import {Button, Spinner} from 'react-bootstrap'
import Step2 from './Step2'
import Step3 from './Step3'
import useCustomizedDashboardValues, {
  customizedDashboardTypes,
  DashboardType,
} from '../../../modules/context/CustomizedDashboardCtx'
import {useEditDashboard} from '../../../../services/customizedDashboard'
import SelectedChecks from './SelectedChecks'
import { notify } from '../../../../helpers'
import { useLocalStorage } from '../../../Hooks/useLocalStorage'

interface ICustomizedForm {
  show: boolean
  handleClose: () => void
  memoizedDashboard?: DashboardType
  widgetDetails?: IWidgets
  editMode?: boolean
  onAssignGraph?: (state: IWidgets) => void
  graphAssignMode?: boolean
}
type GridListType = 'mode_2' | 'mode_3' | 'mode_4';

const CustomizedForm = (props: ICustomizedForm) => {
  const [, customizedDispatch] = useCustomizedDashboardValues()
  const {mutateAsync: editReq, isLoading} = useEditDashboard()
  const [gridList, setGridList] = useLocalStorage<GridListType>('customizedGraphGridList', 'mode_2')
  // const gridList:GridListType = localStorage.getItem('customizedGraphGridList')

  const {
    show,
    handleClose,
    memoizedDashboard,
    editMode = false,
    widgetDetails,
    graphAssignMode = false,
    onAssignGraph,
  } = props
  const intl = useIntl()
  // Initialize the state using useReducer
  const [state, dispatch] = useReducer(
    reducer,
    widgetDetails ?? {
      id: '',
      name: '',
      type: 'graph',
      enable: true,
      widget_type: 'server',
      server_id: null,
      module_id: [],
      widgets: [],
    }
  )

  useEffect(() => {
    if (widgetDetails) {
      dispatch({
        type: 'SET_INIT',
        payload: widgetDetails,
      })
    }
  }, [JSON.stringify(widgetDetails)])

  const checkSteps = [1, 2]
  const serverSteps = [1, 2, 3]

  const [step, setStep] = useState(graphAssignMode ? 0 : 1)

  const validationSteps = (stepOfuser: number) => {
    if (stepOfuser == 1) {
      return graphAssignMode ? false : !state.name
    }

    if (stepOfuser == 2) {
      if (state.widget_type == 'server') {
        return !state.server_id
      } else {
        return !!!state?.module_id.length
      }
    }

    if (stepOfuser == 3) {
      return !!!state?.widgets.length
    }

    return false
  }

  const memoizedDispatch = useCallback((data: Action) => {
    dispatch(data)
  }, [])

  const handleNext = () => {
    setStep((pre) => pre + 1)
  }

  const handleBack = () => {
    setStep((pre) => pre - 1)
  }

  const onClickCreate = () => {
    if (graphAssignMode) {
      onAssignGraph?.(state)
      dispatch({type: 'RESET'})
      notify({
        type: 'success',
        message: intl.formatMessage({id: 'LABELS.ASSIGNED.GRAPHS.SUCCESS'}),
      })
      setStep(0)
    } else {
      handleCreate()
    }
  }

  const handleCreate = async () => {    
    try {
      if (!memoizedDashboard) {   
        return
      }
      //create
      if (!editMode) {
        const findedDashboard: DashboardType = JSON.parse(JSON.stringify(memoizedDashboard))

        const lenOfWigets = findedDashboard.widgets?.widgets?.length
        const ID = `c-widget-${lenOfWigets}`
        // const Column = lenOfWigets % 2 == 0 ? 'column-1' : 'column-2'
      
        const getLeastColumn = (mode:GridListType) => {
          const columns = Object.keys(findedDashboard.widgets[mode]);
          let minColumn = columns[0];
          let minCount = findedDashboard?.widgets[mode][minColumn].widgetsIds.length;
      
          columns.forEach(column => {
            const count = findedDashboard?.widgets[mode][column].widgetsIds.length;
            if (count < minCount) {
              minColumn = column;
              minCount = count;
            }
          });
      
          return minColumn;
        };
      
        const updatedWidgets = { ...findedDashboard.widgets };
        const updatedWidgetsArray = [...findedDashboard?.widgets.widgets, { ...state, id: ID }];
      
        (['mode_2', 'mode_3', 'mode_4'] as GridListType[]).forEach((mode) => {
          if (updatedWidgets[mode]) {
            const Column = getLeastColumn(mode);
            const columnWidgetsIds = updatedWidgets[mode][Column].widgetsIds || [];
            updatedWidgets[mode] = {
              ...updatedWidgets[mode],
              [Column]: {
                ...updatedWidgets[mode][Column],
                widgetsIds: [...new Set([...columnWidgetsIds, ID])],
              },
            };
          }
        });

        await editReq({
          id: memoizedDashboard.id,
          dashboard: {
            ...findedDashboard,
            widgets: {
              ...updatedWidgets,
              widgets: updatedWidgetsArray,
            }
          },
        })
        
        customizedDispatch({
          type: customizedDashboardTypes.ADD_WIDGET,
          payload: {state, id: memoizedDashboard.id},
        })
        dispatch({
          type: 'RESET',
        })
      } else {
        const findedDashboard: DashboardType = JSON.parse(JSON.stringify(memoizedDashboard))

        const findedWidgetIndex = findedDashboard?.widgets?.widgets?.findIndex((w) => w.id == state.id)
        if (findedWidgetIndex < 0) {
          return
        }

        findedDashboard.widgets.widgets[findedWidgetIndex] = {
          ...state,
        }

        await editReq({
          id: memoizedDashboard.id,
          dashboard: {
            ...findedDashboard,
          },
        })
        customizedDispatch({
          type: customizedDashboardTypes.EDIT_WIDGET,
          payload: {state, id: memoizedDashboard.id},
        })
      }

      handleClose()

      setStep(1)
    } catch (error) {}
  }

  const closeHandler=()=>{
    if (graphAssignMode) {
      setStep(0)
    }else{
      setStep(1)
    }
    handleClose()
  }

  return (
    <Modal show={show} onHide={closeHandler} centered>
      <form onSubmit={(e)=>{e.preventDefault()}} className='form'>
      <Modal.Header closeButton className='p-4'>
        <Modal.Title>
          {editMode
            ? intl.formatMessage({id: 'LABELS.EDIT.WIDGET'})
            : intl.formatMessage({
                id: graphAssignMode ? 'LABELS.ASSIGN.GRAPHS' : 'LABELS.CREATE.NEW.WIDGET',
              })}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className='p-4'>
        
        {step > 1 && (
          <ButtonMUI
            onClick={handleBack}
            variant='text'
            startIcon={<span className='fas fa-arrow-left fs-4' />}
            className='text-dark'
          >
            <span className='fs-6'>{intl.formatMessage({id: 'AUTH.GENERAL.BACK_BUTTON'})}</span>
          </ButtonMUI>
        )}
        {step === 0 && (
          <>
            <SelectedChecks
              memoizedDispatch={memoizedDispatch}
              onAssignGraph={onAssignGraph}
              title={graphAssignMode ? intl.formatMessage({id: 'LABELS.ASSIGNED.GRAPHS'}) : ''}
              state={state}
            />
          </>
        )}
        {step === 1 && (
          <>
            <Step1
              name={state.name}
              type={state.type}
              widgetType={state.widget_type}
              memoizedDispatch={memoizedDispatch}
              editMode={editMode}
              graphAssignMode={graphAssignMode}
            />
          </>
        )}

        {step === 2 && (
          <Step2
            memoizedDispatch={memoizedDispatch}
            widgetType={state.widget_type}
            serverId={state.server_id}
            moduleId={state.module_id}
            graphAssignMode={graphAssignMode}
          />
        )}
        {step === 3 && (
          <Step3
            memoizedDispatch={memoizedDispatch}
            widgetType={state.widget_type}
            serverId={state.server_id}
            moduleId={state.module_id}
            widgets={state.widgets}
            graphAssignMode={graphAssignMode}
          />
        )}
      </Modal.Body>
      <Modal.Footer className='p-4'>
        <Button size='sm' variant='secondary' onClick={closeHandler} className='w-100px'>
          {intl.formatMessage({id: 'SERVICEMODAL.BUTTON_CLOSE'})}
        </Button>
        {state?.widget_type === 'check' ? (
          <>
            {checkSteps?.[step - 1] !== checkSteps?.length ? (
              <Button
                size='sm'
                type='submit'
                variant='primary'
                onClick={handleNext}
                className='w-100px'
                disabled={validationSteps(step)}
              >
                {intl.formatMessage({id: step===0 ? 'ACTIONS.NEW' : 'NEXT'})}
              </Button>
            ) : (
              <Button
                size='sm'
                type='submit'
                onClick={onClickCreate}
                className='w-100px'
                disabled={validationSteps(step) || isLoading}
              >
                {isLoading ? (
                  <Spinner animation='grow' size='sm' />
                ) : editMode ? (
                  intl.formatMessage({id: 'ACCESS_LIST.LABELS.EDIT'})
                ) : (
                  intl.formatMessage({id: graphAssignMode ? 'LABELS.ASSIGN' : 'ACTIONS.CREATE'})
                )}
              </Button>
            )}
          </>
        ) : (
          <>
            {serverSteps?.[step - 1] !== serverSteps?.length ? (
              <Button
                size='sm'
                type='submit'
                variant='primary'
                onClick={handleNext}
                className='w-100px'
                disabled={validationSteps(step)}
              >
                {intl.formatMessage({id: step === 0 ? 'ACTIONS.NEW' : 'NEXT'})}
              </Button>
            ) : (
              <Button
                size='sm'
                type='submit'
                onClick={onClickCreate}
                className='w-100px'
                disabled={validationSteps(step) || isLoading}
              >
                {isLoading ? (
                  <Spinner animation='grow' size='sm' />
                ) : editMode ? (
                  intl.formatMessage({id: 'ACCESS_LIST.LABELS.EDIT'})
                ) : (
                  intl.formatMessage({id: graphAssignMode ? 'LABELS.ASSIGN' : 'ACTIONS.CREATE'})
                )}
              </Button>
            )}
          </>
        )}
      </Modal.Footer>
      </form>
    </Modal>
  )
}

export default CustomizedForm
