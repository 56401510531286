import TitleBar from '../../modules/global/TitleBar'
import TabWrapper from '../../modules/accounts/components/Tab/TabWrapper'
import User from '../../modules/accounts/components/User/User'
import AccountCard from '../../modules/accounts/components/AccountCard'
import SubAccountsTable from '../../modules/accounts/components/SubAccountsTable'
import ReactTooltip from 'react-tooltip'
import './Account.css'
import ApiKeyList from '../../modules/accounts/components/ApiAccess/ApiKeyList'
import MetaHead from '../../modules/global/MetaHead/MetaHead'
import {FormattedMessage, useIntl} from 'react-intl'
import {UserModel} from '../../modules/auth/models/UserModel'
import {shallowEqual, useSelector} from 'react-redux'
import {RootState} from '../../../setup'
import {CLIENT, getUserType, hasUserAccess} from '../../../helpers'
import {useEffect, useState} from 'react'
import ApiKeyAdd from '../../modules/accounts/components/ApiAccess/ApiKeyAdd'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import {Link, useNavigate} from 'react-router-dom'
import Sessions from '../../modules/accounts/components/Sessions'
import './style.scss'
// import '../Billing/style.scss'
import {isHasAccess} from '../../../helpers/isHasAccess'
import WarnAlert from '../../modules/global/WarnAlert'
import {ResendEmailConfirmation} from '../../modules/client/ResendEmailConfirmation'
import ApiAccess from './ApiAccess'
import useActionControll from '../../Hooks/useActionControll'
import PaidModal from '../../modules/global/PaidModal'
import {Dropdown, DropdownButton} from 'react-bootstrap'
import IconButton from '../../modules/global/IconButton'

const breadCrumbs: Array<PageLink> = [
  {
    title: 'Xitoring',
    path: '/',
    isSeparator: false,
    isActive: false,
  },
]
const Account = (props: any) => {
  //TODO use css module class
  const intl = useIntl()
  const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel
  const [isAddApi, setIsAddApi] = useState(false)
  const [needsReloadApis, setNeedsReloadApis] = useState(false)
  const navigate = useNavigate()
  const {actionFunc, isLimitModal, closeLimitModal} = useActionControll('contacts')
  const [showPassModal, setShowPassModal] = useState(false)

  const isAddApiHandler = (value: boolean) => {
    setIsAddApi(value)
  }

  const reloadApisHandler = () => {
    setNeedsReloadApis(!needsReloadApis)
  }

  const addContactHanlder = () => {
    actionFunc(() => navigate('/account/sub-accounts/add'))
  }

  const hasUserAccessHandler = () => {
    return hasUserAccess('contacts', user)
  }
  const main: any = useSelector<RootState>(
    ({billingReducer}) => billingReducer.mainService,
    shallowEqual
  ) as any

  return (
    <>
      <MetaHead title={`Account - Xitoring`} />

      <PageTitle breadcrumbs={breadCrumbs}>
        {intl.formatMessage({id: 'PAGE.TITLES.ACCOUNT'})}
      </PageTitle>
      <ResendEmailConfirmation alertTextId='PROFILE.OVERVIEW.CONFIRM_EMAIL' />
      {main?.is_suspended && (
        <WarnAlert
          text={intl.formatMessage({id: 'service_suspended'})}
          name='suspendserive'
          isWarning={true}
        />
      )}

      <div>
        <div className='row'>
          <div className='col-xxl-7'>
            <AccountCard
              title={intl.formatMessage({id: 'ACCOUNT.LOGGED_IN_USER.TITLE'})}
              canAdd={() => false}
              className='p-0'
              rightNode={
                <>
                  {isHasAccess(null,user) && (
                    <>
                      <span
                        className={'btn btn-icon '}
                        data-kt-menu-trigger={"{default: 'click'}"}
                        data-kt-menu-attach='parent'
                        data-kt-menu-placement={'bottom-end'}
                      >
                        <IconButton icon='fa fa-cog' />
                      </span>
                      <div
                        className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-title-gray-700 menu-icon-muted menu-active-bg menu-state-primary fw-semibold py-4 fs-base w-175px'
                        data-kt-menu='true'
                      >
                        {/* end::Menu item */}

                        {/* begin::Menu item */}
                        <div className='menu-item px-2 my-0'>
                          <Link to='/profile/settings' className='text-dark '>
                            <div className='d-flex gap-2 align-items-center fs-7 bg-hover-secondary rounded p-2'>
                              <i className='fa fa-edit fs-4 text-dark'></i>
                              <FormattedMessage id='ACTIONS.EDIT' />
                            </div>
                          </Link>
                        </div>
                        {/* begin::Menu item */}
                        <div className='menu-item px-2 my-0 '>
                          <div
                            onClick={()=>setShowPassModal(true)}
                            className={`d-flex gap-2 align-items-center fs-7 bg-hover-secondary rounded p-2 mt-1`}
                          >
                            <i className='fa fa-key fs-4 text-dark'></i>

                            <FormattedMessage id='PROFILE.SETTING.CHANGE_PASSWORD' />
                          </div>
                        </div>
                        {/* end::Menu item */}
                      </div>
                    </>
                  )}
                </>
              }
            >
              <User showPassModal={showPassModal} setShowPassModal={setShowPassModal} />
            </AccountCard>
            <div className='mt-5'>
              <AccountCard
                title={intl.formatMessage({id: 'ACCESS_LIST.LABELS.BILLING'})}
                canAdd={() => false}
                className='p-0'
              >
                <TabWrapper />
              </AccountCard>
            </div>
          </div>
          <div className='col-xxl-5 mt-10 mt-xxl-0'>
            {isHasAccess(null,user) && (
              <div className='mb-5'>
                <AccountCard
                  title={<FormattedMessage id='ACCOUNT.SUB_ACCOUNTS.TITLE' />}
                  tooltip={intl.formatMessage({id: 'ACCOUNT.SUB_ACCOUNTS.INFO'})}
                  // onAdd={addContactHanlder}
                  canAdd={() => false}
                  // canAdd={hasUserAccessHandler}
                  className='p-3 '
                  rightNode={
                    <>
                      <span
                        className={'btn btn-icon '}
                        data-kt-menu-trigger={"{default: 'click'}"}
                        data-kt-menu-attach='parent'
                        data-kt-menu-placement={'bottom-end'}
                      >
                        <IconButton icon='fas fa-plus fs-1x' />
                      </span>
                      <div
                        className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-title-gray-700 menu-icon-muted menu-active-bg menu-state-primary fw-semibold py-4 fs-base w-175px'
                        data-kt-menu='true'
                      >
                        {/* begin::Menu item */}
                        <div className='menu-item px-2 my-0'>
                          <Link to='/account/sub-accounts/add' className='text-dark '>
                            <div className='d-flex gap-2 align-items-center fs-7 bg-hover-secondary rounded p-2'>
                              <FormattedMessage id='PAGE.TITLES.ADD_SUB_ACCOUNT' />
                            </div>
                          </Link>
                        </div>
                        <div className='menu-item px-2 my-0'>
                          <Link to='/account/sub-accounts/invite' className='text-dark '>
                            <div className='d-flex gap-2 align-items-center fs-7 bg-hover-secondary rounded p-2'>
                               {/* <i className="bi bi-person-plus-fill text-dark"></i> */}
                              <FormattedMessage id='PAGE.TITLES.INVITE_TEAMMATE' />
                            </div>
                          </Link>
                        </div>
                        {/* end::Menu item */}
                      </div>
                    </>
                  }
                >
                  <SubAccountsTable />
                </AccountCard>
              </div>
            )}

            <div>
              {' '}
              <AccountCard
                title={intl.formatMessage({id: 'ACCOUNT.APIS.TITLE'})}
                tooltip={intl.formatMessage({id: 'ACCOUNT.APIS.INFO'})}
                // onAdd={isAddApiHandler.bind(null, true)}
                // changeIcon={isAddApi}
                // onChangeIcon={isAddApiHandler.bind(null, false)}
                // canAdd={() => isHasAccess('api', user)}
                canAdd={() => false}
                className={'p-0'}
                noBorder={true}
              >
                {isHasAccess('api', user) ? (
                  <>
                    {isAddApi && <ApiKeyAdd onReloadApis={reloadApisHandler} />}
                    <ApiAccess />
                    {/* <ApiKeyList reload={needsReloadApis} /> */}
                  </>
                ) : (
                  <h3 className='text-center fs-5 text-dark'>
                    {intl.formatMessage({id: 'ERROR.403.TITLE'})}
                  </h3>
                )}
              </AccountCard>
            </div>
            <div className='mt-5'>
              {' '}
              <AccountCard
                title='Sessions'
                tooltip='Here your current session, you can logout them if you are willing.'
                canAdd={() => false}
                className='p-3'
              >
                {hasUserAccessHandler() ? (
                  <Sessions />
                ) : (
                  <h3 className='text-center fs-5 text-dark'>
                    {intl.formatMessage({id: 'ERROR.403.TITLE'})}
                  </h3>
                )}
              </AccountCard>
            </div>
          </div>
        </div>
        <ReactTooltip className='tooltip-wrapper' />
      </div>
      <PaidModal
        title={intl.formatMessage({id: 'CONTACTS.PAGE.LIMIT'})}
        open={isLimitModal}
        onClose={closeLimitModal}
      />
    </>
  )
}

export default Account
