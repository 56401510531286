import React from 'react'
import {useDispatch} from 'react-redux'
import {shallowEqual, useSelector} from 'react-redux'
import {RootState} from '../../setup'
import {MainConfig, Options} from '../modules/auth/models/MainConfig'
import {getMainServiceAction, setMainState} from '../store/billing/actions'

const useMainConfig: () => MainConfig & {
  reFetchMain: () => void
  isPlanFullyFree: boolean
  updateMainState: (obj: Record<string, any>) => void
  checkLimittedPlan: any
  isSubscribeAvail:()=>boolean
} = () => {
  const main: MainConfig = useSelector<RootState>(
    ({billingReducer}) => billingReducer.mainService,
    shallowEqual
  ) as MainConfig

  const dispatch = useDispatch()

  const updateMainState = (obj: Record<string, any>) => {
    dispatch(setMainState(obj))
  }

  const reFetchMain = () => {
    if (main?.is_free_plan) {
      dispatch(getMainServiceAction())
    }
  }

  const isPlanFullyFree = main?.is_free_plan && !main?.is_in_trial

  const checkLimittedPlan = (name: keyof Options): boolean => {
    const option = main?.options?.[name];
    if (option === undefined && main?.is_free_plan) {
      return true;
    }
    if (option === undefined) {
      return false;
    }
    if (option) {
      return option.quantity === 0 ? true : (option.quantity === 1 || option.quantity == '-1') ? false : true;
    }
    return false;
  };

  const isSubscribeAvail=()=>{
    const status_page_subscribers = main?.options?.['status_page_subscribers'];
    if (status_page_subscribers === undefined && main?.is_free_plan) {
      return false;
    }
    if (status_page_subscribers === undefined) {
      return true;
    }

    if (status_page_subscribers) {
      return status_page_subscribers.quantity == 0 ? false : (status_page_subscribers.quantity > 1 || status_page_subscribers.quantity == -1) ? true : false;
    }

    return true;
  }

  return {...main, isPlanFullyFree, reFetchMain, updateMainState, checkLimittedPlan,isSubscribeAvail}
}

export default useMainConfig
