import React, {ChangeEvent, useRef} from 'react'
import {Select, SelectProps} from '@mui/material'

interface IMuiSelect extends SelectProps {
  children: React.ReactNode;
  fullWidth?: boolean;
  hasError?: boolean;
  size?: 'small' | 'medium';
  onChange: (event: any) => void;
  height?: 'sm' | 'md' | number
  defaultStyles?: boolean
  width?:string|number
}
const MuiSelect: React.FC<IMuiSelect> = ({
  children,
  fullWidth = true,
  hasError,
  size = 'small',
  onChange,
  height = 'md',
  defaultStyles,
  width,
  ...props
}) => {
  return (
    <Select
      {...props}
      fullWidth={fullWidth}
      onChange={onChange}
      size={size}
      sx={{
        height: height == 'sm' ? 38 : height == 'md' ? 43 : height,
        ...(width?{width}:{}),
        '& .MuiSelect-select': {
          maxHeight: height == 'sm' ? 16 : height == 'md' ? 21 : height - 22,
        },
        '& .MuiSelect-outlined': {
          whiteSpace: 'unset !important',
          overflow: 'auto !important',
          // height: '-webkit-fill-available !important',
          placeContent: 'center',
          wordBreak: 'break-all'
        },

        borderRadius: '7.5px', 
        boxShadow: hasError ? '0 0 3px 0.2px var(--kt-danger) !important' : 'none',
        backgroundColor: 'var(--kt-card-bg)',
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: hasError
            ? 'var(--kt-danger) !important'
            : 'var(--kt-input-border-color) !important',
        },
        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
          borderColor: hasError
            ? 'var(--kt-danger) !important'
            : 'var(--kt-input-border-color) !important',
        },
      }}
      MenuProps={{
        disableScrollLock: true,
        PaperProps: {
          style: {
            maxHeight: 350,
            overflowY: 'auto',
          },
        },
        anchorOrigin:{
          vertical: 'bottom',
          horizontal: 'left',
        },
        transformOrigin:{
          vertical: 'top',
          horizontal: 'left',
        }
      }}
    >
      {children}
    </Select>
  )
}

export default MuiSelect
