import React from 'react'
import {TextField, TextFieldProps} from '@mui/material'

interface IMuiTextFieldAdditionalProps {
  fullWidth?: boolean
  hasError?: boolean
  hasWarning?: boolean
  size?: 'small' | 'medium'
  onChange?: (event: any) => void
  height?: 'sm' | 'md' | number
  defaultStyles?: boolean
}

type IMuiTextField = TextFieldProps & IMuiTextFieldAdditionalProps

const MuiTextField: React.FC<IMuiTextField> = ({
  fullWidth = true,
  hasError,
  hasWarning,
  size = 'small',
  onChange,
  height = 'md',
  defaultStyles,
  sx,
  ...props
}) => {
  return (
    <TextField
      {...props}
      fullWidth={fullWidth}
      onChange={onChange}
      size={size}
      error={hasError}
      InputProps={{...props?.InputProps,inputProps: {min: 0}}}
      sx={{
        width: '100%',

        '& input': {
          width: '100%',
        cursor:props?.disabled?"not-allowed":"auto",

        },
        '& .MuiOutlinedInput-root': {
          minHeight: height == 'sm' ? 38 : height == 'md' ? 43 : height,
          boxShadow: hasError ? '0 0 3px 0.2px var(--kt-danger) !important' : 'none',
          borderRadius: '0.475rem',
          bgcolor: 'var(--kt-card-bg)',
          '& fieldset': {
            borderColor: hasError
              ? 'var(--kt-danger) !important'
              : hasWarning
              ? 'var(--kt-warning) !important'
              : 'var(--kt-input-border-color) !important',
            borderWidth: '1px',
          },
          '&:hover fieldset': {
            borderColor: 'var(--kt-input-border-color)',
            borderWidth: '1px',
          },
          '&.Mui-focused fieldset': {
            borderColor: 'var(--kt-input-border-color)',
            borderWidth: '1px',
          },
        },
        ...sx,
      }}
    />
  )
}

export default MuiTextField

// input-group-text {
  // color: var(--kt-input-group-addon-color);
  // background-color: var(--kt-input-group-addon-bg);
  // border: 1px solid var(--kt-input-group-addon-border-color);
// }