/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useEffect, useState} from 'react'
import {KTSVG} from '../../../../../_metronic/helpers'
import {FormattedMessage, useIntl} from 'react-intl'
import * as Yup from 'yup'
import {useFormik} from 'formik'
// import { ToastContainer } from "react-toastify";
import {notify} from '../../../../../helpers'
import {IUpdatePassword, updatePassword} from '../../../client/setting/SettingsModel'
import './style.scss'
import RandomPassword from '../../../widgets/components/RandomPassword'
import {PasswordMeterComponent} from '../../../../../_metronic/assets/ts/components'
import PasswordMeter from '../../../global/PasswordMeter'
import {Modal} from 'react-bootstrap'

interface IPasswordChange {
  username: string
  onSavePassword?: any
  show: boolean
  setShow: any
}

const PasswordChange = ({username, onSavePassword, show, setShow}: IPasswordChange) => {
  const intl = useIntl()
  const [passwordUpdateData, setPasswordUpdateData] = useState<IUpdatePassword>(updatePassword)
  const [loading, setLoading] = useState(false)
  const [showPass, setShowPass] = useState<boolean>(false)

  const passwordFormValidationSchema = Yup.lazy(() =>
    Yup.object().shape({
      newPassword: Yup.string()
        .min(8, intl.formatMessage({id: 'PASSWORD.VALIDATION.8CHAR'}))
        .matches(/[a-z]/, intl.formatMessage({id: 'PASSWORD.VALIDATION.LOWER'}))
        .matches(/[A-Z]/, intl.formatMessage({id: 'PASSWORD.VALIDATION.UPPER'}))
        .matches(/\d/, intl.formatMessage({id: 'PASSWORD.VALIDATION.NUMBER'})),
      passwordConfirmation: Yup.string()
        .min(8)
        .required()
        .oneOf(
          [Yup.ref('newPassword'), null],
          intl.formatMessage({id: 'PROFILE.SETTING.PASSWORD_MATCH'})
        ),
    })
  )

  const formik = useFormik<IUpdatePassword>({
    initialValues: {
      ...passwordUpdateData,
    },
    validationSchema: passwordFormValidationSchema,
    validateOnChange: false,
    validateOnBlur: false,
    enableReinitialize: true,
    onSubmit: (values) => {
      setLoading(true)

      onSavePassword(values.newPassword)
        .then((response: any) => {
          setPasswordUpdateData(values)

          setLoading(false)

          notify({
            type: 'success',
            message: intl.formatMessage({
              id: 'NOTIFICATIONS.PASSWORD_CHANGED',
            }),
          })

          formik.resetForm()

          //close modal
          const closeBtn = window.document.getElementById('close-change-password-btn')!
          closeBtn.click()
        })
        .catch(({data}: any) => {
          setLoading(false)

          let messages = []

          for (let i in data) {
            messages.push(
              <>
                {data[i]}
                <br />
              </>
            )
          }
          notify({type: 'error', message: <>{messages}</>})
        })
    },
  })

  const getRandomPass = async (genPassword: string) => {
    await formik.setFieldValue('newPassword', genPassword)
    await formik.setFieldValue('passwordConfirmation', genPassword)
    formik.setFieldTouched('newPassword', true)
    formik.setFieldTouched('passwordConfirmation', true)
  }

  useEffect(() => {
    const validation_messages = Object(intl?.messages?.VALIDATORS)
    Yup.setLocale({...validation_messages})
  })

  return (
    <>
      <Modal  show={show} onHide={() => setShow(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>
            <h1 className='m-0'>
              <FormattedMessage id='PROFILE.SETTING.CHANGE_PASSWORD' />
            </h1>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form
            onSubmit={formik.handleSubmit}
            id='kt_signin_change_password'
            className='form'
            noValidate
          >
            <div className='d-flex flex-column'>
              <div className='row mb-1'>
                <div className='col-lg-12' data-kt-password-meter='true'>
                  <div className='fv-row mb-4' style={{height: '80px'}}>
                    <label htmlFor='newpassword' className='form-label fs-6 fw-bolder mb-3'>
                      <FormattedMessage id='PROFILE.SETTING.LABELS.NEW_PASSWORD' />
                    </label>
                    <div className=' form-control  form-control-solid d-flex align-items-center gap-1 p-0'>
                      <input
                        type={showPass ? 'text' : 'password'}
                        className='form-control form-control-lg form-control-solid '
                        id='newpassword'
                        {...formik.getFieldProps('newPassword')}
                        autoComplete='off'
                      />
                      <span
                        className={`far ${
                          showPass ? 'fa-eye' : 'fa-eye-slash'
                        } btn btn-icon btn-active-light-primary`}
                        onClick={() => {
                          setShowPass((pre) => !pre)
                        }}
                      ></span>
                      <RandomPassword getPassFunction={getRandomPass} />
                    </div>

                    {formik.touched.newPassword && formik.errors.newPassword && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.newPassword}</div>
                      </div>
                    )}
                    {/* begin::Meter */}
                    <PasswordMeter value={formik.values.newPassword} />
                    {/* end::Meter */}
                  </div>
                </div>

                <div className='col-lg-12'>
                  <div className='fv-row mb-0 mt-10 ' style={{height: '90px'}}>
                    <label htmlFor='confirmpassword' className='form-label fs-6 fw-bolder mb-3'>
                      <FormattedMessage id='PROFILE.SETTING.LABELS.CONFIRM_PASSWORD' />
                    </label>
                    <input
                      type={showPass ? 'text' : 'password'}
                      className='form-control form-control-lg form-control-solid '
                      id='confirmpassword'
                      {...formik.getFieldProps('passwordConfirmation')}
                      autoComplete='off'
                    />
                    {formik.touched.passwordConfirmation && formik.errors.passwordConfirmation && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.passwordConfirmation}</div>
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <div className='form-text mb-5'>
                <FormattedMessage id='PROFILE.SETTING.PASSWORD_DESCRIPTION' />
              </div>
            </div>

            <div className='d-flex flex-center flex-row-fluid pt-12'>
              <button onClick={() => setShow(false)} className='btn btn-light me-3'>
                <FormattedMessage id='ACTIONS.CANCEL' />
              </button>
              <button id='kt_password_submit' type='submit' className='btn btn-primary me-2 px-6'>
                {!loading && <FormattedMessage id='ACTIONS.SAVE' />}
                {loading && (
                  <span className='indicator-progress' style={{display: 'block'}}>
                    <FormattedMessage id='ACTIONS.WAIT' />{' '}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  )
}

export {PasswordChange}
